import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/contact.scss"

const ContactPage = () => (
    <Layout>
        <SEO title="Contact"/>
        <div className="contact">
            <div>
                <h1>
                    <Link to="/">
                        DigitalPaper<span>Green</span>
                    </Link>
                </h1>
                <p>content publishing</p>
            </div>
            <div className="contact-text">
                <div>
                    <form id="contact" name="contact-form" method="POST" data-netlify="true">
                        <input type="hidden" name="form-name" value="contact-form" />
                        <fieldset>
                            <input type="text" name="name" placeholder="Name"/>
                        </fieldset>
                        <fieldset>
                            <input type="email" name="email" placeholder="Email"/>
                        </fieldset>
                        <fieldset>
                            <textarea name="message" placeholder="Type your message here...." required></textarea>
                        </fieldset>
                        <fieldset>
                            <button name="submit" type="submit">Submit
                            </button>
                        </fieldset>
                    </form>
                </div>
                <div>
                    <p>
                        <strong>Digital Paper Green</strong><br/>
                        ul. Sw. Tomasza 35/1 <br/>
                        Krakow, PL 31-027
                    </p>

                    <p>
                        <b>Operated by:</b> <br/>
                        SEKEN CONSULTING <br/>
                        Piotr Sienkiewicz <br/>
                        ul. Wielicka 93/21 <br/>
                        Krakow, 30-552
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactPage
